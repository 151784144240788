<template>
  <div class="topNav"></div>

  <div class="topHead">
    <h1 class="heading">
      <strong> <a href="/">EzWhatsApp</a> </strong>
    </h1>
    <p class="headDesc">
      <strong style="font-weight: bold"
        >Send messages to WhatsApp users without adding them to your
        contacts.</strong
      >
    </p>
    <p class="hintText">
      Paste the number with the country code and it will be automatically
      detected.
    </p>
    <form class="numberGroup" @submit.prevent="chatBtnClick">
      <div class="phoneInput">
        <MazPhoneNumberInput
          v-model="phoneNumber"
          show-code-on-list
          color="secondary"
          size="lg"
          default-country-code="SA"
        />
      </div>
      <button class="chatBtn">OPEN CHAT</button>
    </form>
  </div>
  <div class="textContent">
    <div v-for="(tip, index) in tipContentList" :key="index">
      <h1>{{ tip.title }}</h1>
      <p>
        {{ tip.content }}
      </p>
      <hr style="background-color: grey; margin: 22px" size="5" />
    </div>
  </div>
</template>

<script>
import MazPhoneNumberInput from "maz-ui/components/MazPhoneNumberInput";

export default {
  data() {
    return {
      phoneNumber: null,
      whatsLink: null,
      tipContentList: [
        {
          title: "How to use this tool?",
          content:
            "Type the number of the person you want to chat with in the phone number field at the top. Then press 'Open Chat' button. This will transfer you to WhatsApp to chat directly without saving the number to your contacts.",
        },
      ],
    };
  },

  methods: {
    chatBtnClick(e) {
      e.preventDefault();
      this.whatsLink = "https://wa.me/" + this.phoneNumber;
      window.open(this.whatsLink, "_blank");
      window.location.reload();
    },
  },
  components: {
    MazPhoneNumberInput,
  },
};
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=M+PLUS+Rounded+1c:wght@800&family=Roboto:wght@700&display=swap");

#app {
  font-family: "Roboto", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: white;
}

.nobold {
  font-weight: normal;
}

.hintText {
  font-size: 1rem;
  color: rgba(255, 255, 255, 0.63);
}

body {
  background-color: #2c3e50;
  margin: 0;
}
.topHead {
  width: 70%;
  display: inline-block;
}

.heading {
  font-size: 7vw;
  font-weight: 700;
  margin-bottom: 0;
  background: #47c857;
  background: linear-gradient(to bottom right, #47c857 0%, #3dd6c9 100%);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.topNav {
  width: 100%;
  height: 50px;
  background: #47c857;
  background: linear-gradient(to bottom right, #47c857 0%, #3dd6c9 100%);
}

.headDesc {
  font-size: 1.5rem;
}

.phoneInput {
  width: 400px;
  display: inline-block;
}

.numberGroup {
  display: flex;
  justify-content: center;
  gap: 20px;
}

.chatBtn {
  background: #47c857;
  background: linear-gradient(to bottom right, #47c857 0%, #3dd6c9 100%);
  border-radius: 8px;
  padding: 12px;
  color: white;
  font-weight: bold;
  font-size: 1.2rem;
  cursor: pointer;
}

.textContent {
  text-align: left;
  font-size: 1.5rem;
  color: rgb(44, 44, 44);
  display: inline-block;
  width: 50%;
  background-color: white;
  padding: 50px;
  margin: 50px;
  border-radius: 20px;
}

@media (max-width: 1220px) {
  .textContent {
    width: 80%;
  }
  .heading {
    font-size: 12vw;
  }
}
@media (max-width: 700px) {
  .topHead {
    width: 90%;
  }
  .headDesc {
    font-size: 3vw;
  }
}
@media (max-width: 600px) {
  .topHead {
    width: 95%;
  }
  .textContent {
    width: 95%;
    margin: 50px 5px;
  }
  .numberGroup {
    flex-wrap: wrap;
  }
  .chatBtn {
    font-size: 1.5rem;
    padding: 20px 70px;
  }
}
@media (max-width: 460px) {
  .heading {
    font-size: 3.2rem;
  }
  .headDesc {
    font-size: 1rem;
  }
  .chatBtn {
    font-size: 1.2rem;
    padding: 12px 60px;
  }
  .textContent h1 {
    font-size: 2rem;
  }
  .textContent p {
    font-size: 1.2rem;
  }
}

@media (max-width: 305px) {
  .heading {
    font-size: 10vw;
  }
}
</style>
